// @ts-ignore
import { on } from 'common/js/utils/dom';

function submitSearchInput(formAction: string, searchPhraseInput: HTMLInputElement) {
    const { value } = searchPhraseInput;
    const searchPhrase = value.trim();

    window.location.href = `${formAction}/${searchPhrase}`;
}

on('submit', '[data-js-search-mobile]', (event, el) => {
    const action = document.querySelector<HTMLFormElement>('[data-js-search-mobile]')?.action;
    // @ts-ignore
    const input = el.querySelector<HTMLInputElement>('[data-js-search-input-mobile]');

    if (action && input) {
        event.preventDefault();
        submitSearchInput(action, input);
    }
});

on(
    'keypress',
    '[data-js-search-input]',
    (event:KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (event.target instanceof HTMLInputElement) {
                event.target.parentElement?.querySelector<HTMLButtonElement>('[data-js-search-button]')?.click();
            }
        }
    },
);
